import React from 'react';
import {
    Div, P, svgSprite, H2, Slider
} from '../../../hybrid/wrappers';

const RoadmapItem = ({styles, data, index, currentIndex, currentData}) => {
    const circlesStyles = currentData === data?.id ? styles.roadmapProgressCircleAdd : styles.roadmapProgressCircle
    return (
        <Div className={index % 2 === 0 ? styles.roadmapItemMainWrapperReverse : styles.roadmapItemMainWrapper} id={data?.id}>
            <Div className={styles.upperRoadmapItem}>
                <Div className={styles.roadmapTitleItem}>
                    {data?.title}
                </Div>
                <Div>
                    {data?.description?.map(item => {
                        return (
                            <Div>
                                <Div className={styles.roadmapDescriptionWrapperItem}>
                                    {data?.description?.length > 1 ? (
                                        <Div className={styles.dotWrapper}>
                                            <Div className={styles.itemDot}></Div>
                                        </Div>
                                    ) : null}
                                    <Div className={styles.roadmapDescriptionItem}>
                                        {item}
                                    </Div>
                                </Div>
                            </Div>
                        )
                    })}
                </Div>
            </Div>
            <Div className={currentIndex > index ? styles.roadmapProgressFill : styles.roadmapProgress}>
                <Div className={circlesStyles}>
                    {svgSprite(currentData === data?.id ? 'eternity-landing-roadmapcircleFill' : 'eternity-landing-roadmapcircle')}
                </Div>
            </Div>
            <Div className={styles.roadmapEmpty}></Div>
        </Div>
    )
}
export default RoadmapItem