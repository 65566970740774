// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import { getLangPaths, getUserStaticProps, getDefaultStaticProps } from '../../common/utils/utils';
import Landing from '../../layouts/landing/Landing';
import HeroSection from '../../views/landing/hero/HeroSection';
import AboutSection from '../../views/landing/about/AboutSection';
import StagesSection from '../../views/landing/stages/StagesSection';
import WalletsSection from '../../views/landing/wallet/WalletSection';
import PortfolioSection from '../../views/landing/portfolio/PortfolioSection';
import PaymentSection from '../../views/landing/payment/PaymentSection';
import CommunitySection from '../../views/landing/community/CommunitySection';
import PartnersSection from '../../views/landing/partners/PartnersSection';
import InvestitionsSection from '../../views/landing/investitions/InvestitionsSections';
import VideoSection from '../../views/landing/video/VideoSection';
import RoadmapSection from '../../views/landing/roadmap/RoadmapSection';

const App = () => {
    return (
        <Landing isLandingPage={true}>
            <HeroSection />
            {/* <InvestitionsSection /> */}
            <AboutSection />
            <StagesSection />
            <WalletsSection />
            <PortfolioSection />
            {/* <PaymentSection /> */}
            {/* <CommunitySection /> */}
            {/* <VideoSection /> */}
            <RoadmapSection />
            <PartnersSection />
        </Landing>
    )
};
export async function getStaticPropsFunction({ params }) {
    return await getUserStaticProps(params, '/');
}
export async function getStaticPaths() {
    const paths = await getLangPaths();
    return {
        paths,
        fallback: true
    }
}

const getStaticProps = getStaticPropsFunction;
export { getStaticProps };

export default App;