import T from '../../../common/components/T'

export const roadmapData = [
    {
        id: '2014-2015',
        title: '2014-2015',
        description: [
            <T textName='2014-2015-title1-roadmap-new' defaultStr='Создание эффективной модели работы на финансовых рынках.' page='/' />,
            <T textName='2014-2015-title2-roadmap-new' defaultStr='Разработка алгоритмов.' page='/' />,
        ]
    },
    {
        id: '2015',
        title: '2015',
        description: [<T textName='2015-title-roadmap-new' defaultStr='Сформирована команда трейдеров и финансовых аналитиков.' page='/' />]
    },
    {
        id: '2015-2020',
        title: '2015-2020',
        description: [<T textName='2015-2020-title-roadmap-new' defaultStr='Переговоры, подписание контрактов с частными инвесторами и венчурными фондами на инвестиции от 1 миллиона долларов США.' page='/' />]
    },
    {
        id: '2022-2023',
        title: '2022-2023',
        description: [
            <T textName='2022-2023-title1-roadmap-new' defaultStr='Формирование стратегии масштабирования бизнеса через сообщество сетевого маркетинга;' page='/' />,
            <T textName='2022-2023-title2-roadmap-new' defaultStr='Переговоры с ведущими экспертами в индустрии сетевого маркетинга.' page='/' />,
        ]
    },
    {
        id: 'Q22023',
        title: 'Q2 2023',
        description: [
            <T textName='Q22023-title1-roadmap-new' defaultStr='Eternity INC находится в стадии формирования;' page='/' />,
            <T textName='Q22023-title2-roadmap-new' defaultStr='Подключение первых лидеров сетевого маркетинга;' page='/' />,
            <T textName='Q22023-title3-roadmap-new' defaultStr='Внедряются платежные шлюзы: SEPA, банковские переводы;' page='/' />,
            <T textName='Q22023-title4-roadmap-new' defaultStr='Внедряются платежные шлюзы VISA/MASTERCARD;' page='/' />,
            <T textName='Q22023-title5-roadmap-new' defaultStr='Создаются платежные шлюзы для вывода средств в криптовалютах.' page='/' />,
        ]
    },
    {
        id: 'Q32023',
        title: 'Q3 2023',
        description: [<T textName='Q32023-title-roadmap-new' defaultStr='Мероприятие в Вене с участием первых лиц Eternity INC.' page='/' />]
    },
    {
        id: 'Q12024',
        title: 'Q1 2024',
        description: [<T textName='Q12024-title-roadmap-new' defaultStr='Начало разработок в области искусственного интеллекта для трейдинга.' page='/' />]
    },
    {
        id: 'Q22024',
        title: 'Q2 2024',
        description: [
            <T textName='Q22024-title-roadmap-new' defaultStr='Запуск нового финансового технологического решения на основе блокчейна для сообщества.' page='/' />,
        ]
    },
    {
        id: 'Q32024',
        title: 'Q3 2024',
        description: [
            <T textName='Q32024-title1-roadmap-new' defaultStr='Запуск образовательной академии Eternity;' page='/' />,
            <T textName='Q32024-title2-roadmap-new' defaultStr='Запуск NeoBank. Уникальное решение, объединяющее традиционный банкинг и криптовалюту.' page='/' />,
        ]
    },
    {
        id: 'Q42024',
        title: 'Q4 2024',
        description: [
            <T textName='Q42024-title1-roadmap-new' defaultStr='Запуск блокчейн-кошелька Eternity, созданного с использованием лучших протоколов безопасности;' page='/' />,
            <T textName='Q42024-title2-roadmap-new' defaultStr='Запуск модуля автоматической торговли на основе искусственного интеллекта.' page='/' />,
        ]
    },
    {
        id: 'Q22025',
        title: 'Q2 2025',
        description: [
            <T textName='Q22025-title-roadmap-new' defaultStr='Запуск криптовалютной биржи Eternity.' page='/' />,
        ]
    },
    {
        id: 'Q32025',
        title: 'Q3 2025',
        description: [
            <T textName='Q32025-title-roadmap-new' defaultStr='Запуск новой платформы Eternity INC на web3.' page='/' />,
        ]
    },
    {
        id: 'Q42025',
        title: 'Q4 2025',
        description: [
            <T textName='Q42025-title-roadmap-new' defaultStr='Начало разработки продуктов для Eternity Metaverse.' page='/' />,
        ]
    },
]