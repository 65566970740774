import officeStyles from './office/video-styles'
import mainStyles from './main/video-styles'

const stylesheets = (width, height, variable = {}) => ({
    officeStyles, mainStyles,
    videoContainer: {
        position: 'relative',
        'cursor': 'pointer',
        'z-index': 1
    },
    videoContainerPlaying: { 'z-index': 3 },
    videoButton: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90px',
        height: '90px',
        'border-radius': '45px',
        background: variable.whiteColor,
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
    }
});
export default stylesheets;