import React, {
    useState, useEffect, useContext
} from 'react';
import {
    Div, P, svgSprite, H2
} from '../../../hybrid/wrappers';
import T from '../../../common/components/T';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';
import { navigate } from '../../../common/utils/router';
import UserContext from '../../../common/utils/getContext';
import Image from 'next/image';
import styles from './portfolio.module.scss';

import rightImage from '../../../public/src/img/landing/portfolio/portfolio-desktop.png';
import letters from '../../../public/src/img/landing/portfolio/portfolio-letters.png';

const PortfolioSection = () => {
    const { lang } = useContext(UserContext)
    const windowDimensions = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (windowDimensions < 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowDimensions]);

    return (
        <Div className={styles.mainWrapper}>
            <Div className={styles.mainContainer}>
                <Div className={styles.lettersContainer}>
                    <Image
                        alt='wallet-bg-mobile'
                        src={letters}
                        layout='fill'
                        objectFit='cover'
                        quality='100'
                        placeholder='blur'
                        blurDataURL=''
                    />
                </Div>
                <Div className={styles.contentWrapper}>
                    <Div className={styles.leftCol}>
                        <H2 className={styles.title}>
                            <T textName='portfolio-title-112' defaultStr='Умный' page='/'/>
                        </H2>
                        <H2 className={styles.titleColored}>
                            <T textName='portfolio-title-212' defaultStr='портфель' page='/'/>
                        </H2>
                        <P className={styles.text}>
                            <T textName='portfolio-text-1-new12' defaultStr='Создайте свое портфолио умных инструментов и знаний' page='/'/>
                        </P>
                        {/* <Div
                            className={styles.buttonWrapper}
                            onClick={() => navigate('/[lang]/authentication/[type]', '/authentication/login', lang)}
                        >
                            <T textName='button-portfolio-label' defaultStr='Создать свой портфель' page='all'/>
                            <P className={styles.buttonSvgWrapper}>
                                {svgSprite('register-button-arrow', {
                                    width: isMobile ? '16px' : '24px',
                                    height: isMobile ? '16px' : '24px'
                                })}
                            </P>
                        </Div> */}
                    </Div>
                    <Div className={styles.rightCol}>
                        <Image
                            alt='colored-bg-mobile'
                            src={rightImage}
                            layout='fill'
                            objectFit='cover'
                            quality='100'
                            placeholder='blur'
                            blurDataURL=''
                        />
                    </Div>
                </Div>
            </Div>
        </Div>
    )
}
export default PortfolioSection