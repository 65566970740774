import React, { useState, useEffect } from 'react';
import { svgSprite, Div } from '../../hybrid/wrappers';
import getStyles from '../../project/styles/widget-styles/video-styles';
import Styles from '../../common/decorators/Styles';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

const Video = ({
    url, styles, width, height, playToggler = false
}) => {
    const [load, setLoad] = useState(false);
    const [play, setPlay] = useState(playToggler);

    useEffect(() => {
        setPlay(playToggler)
    }, [playToggler])
    
    return (
        <Div styles={{ ...styles.videoContainer, ...load ? styles.videoContainerPlaying : {} }}>
            <ReactPlayer
                url={url}
                playing={load && play}
                onPlay={() => { setPlay(true) }}
                onPause={() => { setPlay(false) }}
                controls={true}
                width={width || 'auto'}
                height={height || 'auto'}
            />
            {
                !play ?
                    <Div data-uitest='694_uitest' onClick={() => { setPlay(true);setLoad(true); }} styles={styles.videoButton}>
                        {svgSprite('play', {
                            width: '30px',
                            height: '30px',
                            fill: styles.variable.blueColor
                        })}
                    </Div>
                    : null
            }
        </Div>
    )
}
Video.propTypes = {
    styles: PropTypes.object,
    url: PropTypes.string,
    width: PropTypes.number
};
export default Styles(Video, getStyles);