import React, {
    useState, useEffect, useContext, useRef
} from 'react';
import {
    Div, P, svgSprite, H2, Slider
} from '../../../hybrid/wrappers';
import T from '../../../common/components/T';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';
import { getData } from '../../../common/utils/router';
import UserContext from '../../../common/utils/getContext';
import Image from 'next/image';
import styles from './roadmap.module.scss';

import roadmapImage from '../../../public/src/img/landing/roadmap/roadmap.png';
import RoadmapItem from './RoadmapItem';
import { roadmapData } from './initData';

const RoadmapSection = () => {
    const { lang } = useContext(UserContext)
    const windowDimensions = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (windowDimensions < 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowDimensions]);

    const [currentData, setCurrentData] = useState('Q22024')

    useEffect(() => {
        (async() => {
            const data = await getData('/api/v1/landing/current-timecode', false, lang)
            if (!data) {
                return
            } else {
                setCurrentData(data?.quarter_title)
            }
        })()
    }, [lang])

    const containerRef = useRef(null);
    const [startX, setStartX] = useState(0);
    const [isScrollLeft, setIsScrollLeft] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(isScrollLeft);
    // 1549
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        setIsScrollLeft(containerRef?.current?.scrollLeft)
    }, [containerRef?.current])

    useEffect(() => {
        setScrollLeft(isScrollLeft)
    }, [isScrollLeft])

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const x = e.pageX - containerRef.current.offsetLeft;
        const scrollX = x - startX;
        containerRef.current.scrollLeft = scrollLeft - scrollX;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const handleTouchStart = (e) => {
        setIsDragging(true);
        setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        const x = e.touches[0].pageX - containerRef.current.offsetLeft;
        const scrollX = x - startX;
        containerRef.current.scrollLeft = scrollLeft - scrollX;
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const currentTrackIndex = roadmapData.findIndex(item => item.id === currentData)

    const scrollToSection = (sectionId) => {
        const container = containerRef.current;
        const section = document.getElementById(sectionId);
    
        if (container && section) {
            const containerWidth = container.clientWidth;
            const sectionWidth = section.clientWidth;
            const scrollPosition = section.offsetLeft - (containerWidth - sectionWidth) / 2;
            container.scrollLeft = scrollPosition;
        }
    };

    useEffect(() => {
        scrollToSection(currentData);
    }, [currentData])

    return (
        <Div>
            <Div className={styles.mainWrapper}>
                <Div className={styles.roadmapImageWrapper}>
                    <Image
                        alt='roadmap-image'
                        src={roadmapImage}
                        layout='fill'
                        objectFit='cover'
                        quality='100'
                        placeholder='blur'
                        blurDataURL=''
                    />
                </Div>
                <Div className={styles.linesBg}/>
                <Div className={styles.mainContainer}>
                    {/* <Div className={styles.uppervideotext}>
                        <T textName='uppervideotextnew' defaultStr='All rights to this video belong to @NakheelOfficial' page='/'/>
                    </Div> */}
                    <H2 className={styles.roadmapTitle}>
                        <span style={{color: '#2E6D8E'}}>
                            <T textName='roadmap-main-title1new' defaultStr='Дорожная' page='/'/>
                        </span>
                        <span style={{color: '#2E6D8E'}}>
                            <T textName='roadmap-main-title2new' defaultStr=' карта' page='/'/>
                        </span>
                    </H2>
                </Div>
                <div
                    className={styles.scrollableContainer}
                    ref={containerRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <div className={styles.scrollContent}>
                        {roadmapData?.map((item, index) => {
                            return (
                                <RoadmapItem 
                                    data={item}
                                    styles={styles}
                                    index={index}
                                    currentIndex={currentTrackIndex}
                                    currentData={currentData}
                                />
                            )
                        })}
                    </div>
                </div>
            </Div>
        </Div>
    )
}
export default RoadmapSection
