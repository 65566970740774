import React, {
    useState, useEffect, useContext
} from 'react';
import {
    Div, P, svgSprite, H2
} from '../../../hybrid/wrappers';
import T from '../../../common/components/T';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';
import Image from 'next/image';
import styles from './partners.module.scss';
import UserContext from '../../../common/utils/getContext';
import { navigate } from '../../../common/utils/router';

import partnersShakeBg from '../../../public/src/img/landing/partners/handshake_bg.png';
import desktopColored from '../../../public/src/img/landing/partners/colored-bg-partners-desktop.png';
import mobileColored from '../../../public/src/img/landing/partners/colored-bg-partners-mobile.png';

const PartnersSection = () => {
    const { lang } = useContext(UserContext)
    const windowDimensions = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (windowDimensions < 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowDimensions]);

    return (
        <Div className={styles.mainWrapper}>
            <Div className={styles.linesBg}/>
            <Div className={styles.partnersBgWrapper}>
                <Image
                    alt='partners-png'
                    src={partnersShakeBg}
                    layout='fill'
                    objectFit='cover'
                    quality='100'
                    placeholder='blur'
                    blurDataURL=''
                />
            </Div>
            <Div className={styles.mainContainer}>
                <Div className={styles.mainContent}>
                    <Div className={styles.partnersDescriptionWrapper}>
                        <H2 className={[lang === 'en' ? styles.titleInline : styles.title].join(' ')}>
                            <T textName='partners-first-title-new12' defaultStr='Привлекайте' page='/' />
                        </H2>
                        {' '}
                        <H2 className={[lang === 'en' ? styles.titleColoredInline : styles.titleColored].join(' ')}>
                            <T textName='partners-first-title-colored-new12' defaultStr='партнеров' page='/' />
                        </H2>
                        <P className={styles.text}>
                            <T textName='partners-first-text-new12' defaultStr='Получайте больше благодаря совместному расширению бизнес-сообщества.' page='/' />
                        </P>
                        {/* <P className={styles.text}>
                            <T textName='partners-first-text-lower' defaultStr='Ваша прибыль ограничена только вашими принципами.' page='all' />
                        </P> */}
                    </Div>
                </Div>
            </Div>
            <Div className={styles.ctaWrapper}>
                <Image
                    alt='cta-wrapper-png'
                    src={isMobile ? mobileColored : desktopColored}
                    layout='fill'
                    objectFit='cover'
                    quality='100'
                    placeholder='blur'
                    blurDataURL=''
                />
                <Div className={styles.ctaContentWrapper}>
                    <P className={styles.ctaTitle}>
                        <T textName='cta-title-new12' defaultStr='Присоединяйтесь к сообществу' page='/' />
                    </P>
                    <P className={styles.ctaTitleColored}>
                        <T textName='cta-title-colored-new12' defaultStr='прямо сейчас' page='/' />
                    </P>
                    {/* <P className={styles.ctaText}>
                        <T textName='cta-text' defaultStr='и уже через 7 дней получайте свой первый доход без значительных затрат времени!' page='all' />
                    </P> */}
                    <Div className={styles.ctaChangeButton}>
                        <T textName='cta-overbutton-text12' defaultStr='Присоеденитесь сейчас и вы получите безграничные финансовые возможности!' page='/' />
                    </Div>
                    {/* <Div
                        className={styles.ctaButtonWrapper}
                        onClick={() => navigate('/[lang]/authentication/[type]', '/authentication/login', lang)}
                    >
                        <T textName='cta-button-new' defaultStr='Присоединиться' page='all' />
                        <P className={styles.svgWrapper}>
                            {svgSprite('register-button-arrow', {
                                width: isMobile ? '16px' : '24px',
                                height: isMobile ? '16px' : '24px'
                            })}
                        </P>
                    </Div> */}
                </Div>
            </Div>
        </Div>
    )
}
export default PartnersSection