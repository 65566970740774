import React, { useState, useEffect } from 'react';
import {
    Div, P, svgSprite, H2
} from '../../../hybrid/wrappers';
import T from '../../../common/components/T';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';
import Image from 'next/image';
import styles from './stages.module.scss';

import desktopImg from '../../../public/src/img/landing/stages/stages-desktop.png';
import mobileImg from '../../../public/src/img/landing/stages/stages-mobile.png';


const StagesSection = () => {
    const windowDimensions = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (windowDimensions < 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowDimensions]);

    const cardsInfo = [
        {
            id: 'stages1',
            title: <T textName='cards-stages-1new' defaultStr='Стратегия доходности' page='/'/>,
            text: <T textName='cards-stages-description-1-new12' defaultStr='Работаем с торговым диапазоном активов и выбираем лучшие предложения, которые приносят максимум из доступной прибыли по сделкам.' page='/'/>,
            icon: 'stages1'
        },
        {
            id: 'stages2',
            title: <T textName='cards-stages-2new' defaultStr='Стратегия стабильности' page='/'/>,
            text: <T textName='cards-stages-description-2-new12' defaultStr='Работаем с ликвидностью в условиях понятного рыночного максимума и минимума цен, поэтому обеспечиваем прогнозированный результат по прибыли на сделках.' page='/'/>,
            icon: 'stages2'
        },
        {
            id: 'stages3',
            title: <T textName='cards-stages-3new' defaultStr='Стратегия надежности' page='/'/>,
            text: <T textName='cards-stages-description-3-new12' defaultStr='Работаем с трендами рынка для формирования портфеля активов. Стратегия позволяет получать прибыль как во время роста, так и при снижении ключевых экономических показателей.' page='/'/>,
            icon: 'stages3'
        }
    ]

    return (
        <Div className={styles.mainWrapper}>
            <Div className={styles.desktopImageWrapper}>
                <Image
                    alt='stages-bg-desktop'
                    src={desktopImg}
                    layout='fill'
                    objectFit='cover'
                    quality='100'
                    placeholder='blur'
                    blurDataURL=''
                />
            </Div>
            <Div className={styles.mainContainer}>
                <Div className={styles.mobileImageWrapper}>
                    <Image
                        alt='stages-bg'
                        src={mobileImg}
                        layout='fill'
                        objectFit='cover'
                        quality='100'
                        placeholder='blur'
                        blurDataURL=''
                    />
                </Div>
                <Div className={styles.cardsWrapper}>
                    <Div className={styles.titleWrapper}>
                        <H2 className={styles.titleText}>
                            <span>
                                <T textName='stages-main-title-new111' defaultStr='Доступ к' page='/'/>
                            </span>
                            <span style={{color: '#2E6D8E'}}>
                                <T textName='stages-main-title-new211' defaultStr=' лучшим' page='/'/>
                            </span>
                        </H2>
                        <H2 className={styles.titleTextColored}>
                            <T textName='stages-main-title-second-new111' defaultStr=' в мире возможностям' page='/'/>
                        </H2>
                    </Div>
                    <Div className={styles.contentCards}>
                        {cardsInfo.map((item) => {
                            return (
                                <Div
                                    key={item.id}
                                    className={styles.cardsInfoItem}
                                >
                                    <Div className={styles.cardHeader}>
                                        <Div className={styles.cardsInfoSvgWrapper}>
                                            {svgSprite(item.icon, {
                                                width: isMobile ? '48px' : '64px',
                                                height: isMobile ? '48px' : '64px'
                                            })}
                                        </Div>
                                        <P className={styles.cardsInfoTitle}>{item.title}</P>
                                    </Div>
                                    <P className={styles.cardsInfoText}>{item.text}</P>
                                </Div>
                            )
                        })}
                    </Div>
                </Div>
            </Div>
        </Div>
    )
}
export default StagesSection