import React, {
    useState, useEffect, useMemo
} from 'react';
import {
    Div, P, svgSprite
} from '../../../hybrid/wrappers';
import T from '../../../common/components/T';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';
import Image from 'next/image';
import styles from './about.module.scss';

import desktopImg from '../../../public/src/img/landing/about/ben_bg.png';
import mobileImg from '../../../public/src/img/landing/about/ben_bg-mobile.png';


const AboutSection = () => {
    const windowDimensions = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false)
    const [isDesktopLarge, setIsDesktopLarge] = useState(false)
    useEffect(() => {
        if (windowDimensions < 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowDimensions]);
    useEffect(() => {
        if (windowDimensions > 1351) {
            setIsDesktopLarge(true);
        } else {
            setIsDesktopLarge(false);
        }
    }, [windowDimensions]);

    const cardsInfo = useMemo(() => {
        if (isDesktopLarge) {
            return [
                {
                    id: 'about_1',
                    title: <T textName='cards-info-1-new12' defaultStr='Свободное членство в сообществе' page='/'/>,
                    text: <T textName='cards-info-description-1-new12' defaultStr='Станьте членом сообщества бесплатно и получите доступ к образованию' page='/'/>,
                    icon: 'about1'
                },
                {
                    id: 'about_2',
                    title: <T textName='cards-info-2-new12' defaultStr='Безопасность' page='/'/>,
                    text: <T textName='cards-info-description-2-new12' defaultStr='Используем передовые методы обеспечения безопасности нашего сообщества' page='/'/>,
                    icon: 'about2'
                },
                {
                    id: 'about_3',
                    title: <T textName='cards-info-3-new12' defaultStr='Социальная миссия' page='/'/>,
                    text: <T textName='cards-info-description-3-new12' defaultStr='Наша цель – сообщество более 5 миллионов пользователей, получивших финансовые знания' page='/'/>,
                    icon: 'about3'
                },
                {
                    id: 'about_4',
                    title: <T textName='cards-info-4-new12' defaultStr='Без границ' page='/'/>,
                    text: <T textName='cards-info-description-4-new12' defaultStr='Вы можете быть членом сообщества находясь в любой точке земного шара' page='/'/>,
                    icon: 'about4'
                }
            ]
        } else {
            return [
                {
                    id: 'about_1',
                    title: <T textName='cards-info-1-new12' defaultStr='Свободное членство в сообществе' page='/'/>,
                    text: <T textName='cards-info-description-1-new12' defaultStr='Станьте членом сообщества бесплатно и получите доступ к образованию' page='/'/>,
                    icon: 'about1'
                },
                {
                    id: 'about_3',
                    title: <T textName='cards-info-3-new12' defaultStr='Социальная миссия' page='/'/>,
                    text: <T textName='cards-info-description-3-new12' defaultStr='Наша цель – сообщество более 5 миллионов пользователей, получивших финансовые знания' page='/'/>,
                    icon: 'about3'
                },
                {
                    id: 'about_2',
                    title: <T textName='cards-info-2-new12' defaultStr='Безопасность' page='/'/>,
                    text: <T textName='cards-info-description-2-new12' defaultStr='Используем передовые методы обеспечения безопасности нашего сообщества' page='/'/>,
                    icon: 'about2'
                },
                {
                    id: 'about_4',
                    title: <T textName='cards-info-4-new12' defaultStr='Без границ' page='/'/>,
                    text: <T textName='cards-info-description-4-new12' defaultStr='Вы можете быть членом сообщества находясь в любой точке земного шара' page='/'/>,
                    icon: 'about4'
                }
            ]
        }
    }, [isDesktopLarge])

    return (
        <Div className={styles.mainWrapper}>
            <Image
                alt='about-background'
                src={isMobile ? mobileImg : desktopImg}
                layout='fill'
                objectFit='cover'
                quality='100'
                placeholder='blur'
                blurDataURL=''
            />
            <Div className={styles.mainContainer}>
                <Div className={styles.cardsWrapper}>
                    {cardsInfo.map((item) => {
                        return (
                            <Div
                                key={item.id}
                                className={styles.cardsInfoItem}
                            >
                                <Div className={styles.cardsInfoSvgWrapper}>
                                    {svgSprite(item.icon)}
                                </Div>
                                <P className={styles.cardsInfoTitle}>{item.title}</P>
                                <P className={styles.cardsInfoText}>{item.text}</P>
                            </Div>
                        )
                    })}
                </Div>
            </Div>
        </Div>
    )
}
export default AboutSection