import React from 'react';
import T from '../../common/components/T';
import { delToken, SITE_URL } from '../../common/utils/utils';
import { navigate } from '../../common/utils/router';
import { Div, FakeLink } from '../../hybrid/wrappers';

export const handleClick = (
    event, item, lang, submenu, setUserToken, userToken, toggleMenu, setIsNavigate
) => {
    setIsNavigate && setIsNavigate(false)
    if (!event.ctrlKey) {
        event.preventDefault();
    }

    if (item.clickFunc) {
        item.clickFunc();
        return;
    }

    if (item.type === 'part' && item.submenu.length && submenu) {
        return submenu(item);
    }
    if (item.name === 'logout') {
        setIsNavigate && setIsNavigate(true)
        return delToken(userToken, lang, setUserToken)
    }
    if (item.target) {
        setIsNavigate && setIsNavigate(true)
        window.open(item.type === 'link'
            ? `${SITE_URL}/${lang}${item.asLink}`
            : item.type === 'outLink'
                ? `https://${item.asLink}`
                : item.type === 'page'
                    ? `${SITE_URL}/${lang}/page${item.asLink}`
                    : '');
        return false;
    }
    if (item.type === 'link') {
        setIsNavigate && setIsNavigate(true)
        toggleMenu && toggleMenu()
        return navigate(item.link, `${item.asLink}`, lang)
    } else if (item.type === 'outLink') {
        setIsNavigate && setIsNavigate(true)
        document.location = `https://${item.asLink}`;
        return false;
    } else if (item.type === 'page') {
        setIsNavigate && setIsNavigate(true)
        toggleMenu && toggleMenu()
        return navigate(item.link, `/page${item.asLink}`, lang)
    }
};
export const returnHref = (item, lang) => {
    if (item.type === 'part' || item.name === 'logout') {
        return 'javascript:void(0)';
    }
    if (item.type === 'link') {
        return `${SITE_URL}/${lang}${item.asLink}`
    } else if (item.type === 'outLink') {
        return `https://${item.asLink}`;
    } else if (item.type === 'page') {
        return `${SITE_URL}/${lang}/page${item.asLink}`
    }
}

export const checkScrollButtons = (
    transform = 0, scrollElement, innerElement, setBottom, setTop
) => {
    if (scrollElement?.offsetHeight < innerElement?.offsetHeight) {
        if (Math.ceil(-transform + scrollElement.offsetHeight) >= innerElement.offsetHeight) {
            setBottom(false);
        } else {
            setBottom(true);
        }
        if (transform < 0) {
            setTop(true)
        } else {
            setTop(false)
        }
    } else {
        setBottom(false);
        setTop(false)
    }
}
export const calcTransform = (plus, innerElement, scrollElement, transform) => {
    const difference = innerElement?.offsetHeight - scrollElement?.offsetHeight;
    return plus
        ? transform + 200 >= 0 ? 0 : transform + 200
        : -(transform - 200) >= difference ? -difference : transform - 200;
}
export const generateActives = (itemMenu, router, active, lang) => {
    const path = active || router.asPath.replace(`/${lang}`, '');
    let actives = [];
    let subActives = [];

    itemMenu.forEach((item) => {
        if (item.asLink === path) {
            actives.push(item.name)
        }
        if (item.submenu?.length) {
            item.submenu.forEach((s) => {
                if (s.asLink === path) {
                    actives.push(item.name);
                    subActives.push(s.name);
                }
            })
        }
    })

    return {
        active: actives,
        subactive: subActives
    }
}
export const MenuLink = (props) => {
    if (props.type === 'part') {
        return <Div {...props}>{props.children}</Div>
    }
    return <FakeLink {...props}>{props.children}</FakeLink>
}

const itemMenuChild = (submenu, data, lang) => {
    const accum = [];
    if (!!submenu.length) {
        submenu.map((childItem) => {
            const id = childItem._id;
            const childrenData = [];
            data.map((itemData) => {
                if (itemData.parent === id) {
                    childrenData.push(itemData)
                }
            });

            accum.push({
                ...childItem,
                title: childItem.title[lang] || childItem.title.default,
                icon: childItem.icon || (childItem.type === 'part' ? 'menu-category' : 'menu-file'),
                name: childItem._id + '-name',
                link: childItem.type === 'page' ? '/[lang]/[page]' : `/[lang]${childItem.link}`,
                asLink: !!childItem.link ? childItem.link : childItem._id + '_link',
                submenu: itemMenuChild(childrenData, data, lang)
            })
        });
    }
    return accum;
}

export const getItemMenu = (data, lang, outerPart, profileData, setAlertData, queryLang) => {
    const menu = [
        // {
        //     icon: 'menu-report',
        //     link: '/ау',
        //     asLink: '/fee',
        //     showAll: true,
        //     title: <T textName='myPresentationTitle' defaultStr='Моя презентация' page='all' />,
        //     type: 'part',
        //     submenu: [
        //         {
        //             title: <T textName='intropagelabel' defaultStr='Start page' page='all' />,
        //             clickFunc: () => {
        //                 setAlertData({
        //                     type: 'success',
        //                     cont: <T textName='presentationLandingAlert' defaultStr='Ваш лендинг сформирован' page='all' />
        //                 });
        //                 navigate('/[lang]/intro/[id]', `/intro/${profileData?.username}`, queryLang || lang);
        //             },
        //             pressFunc: () => {
        //                 setAlertData({
        //                     type: 'success',
        //                     cont: <T textName='presentationLandingAlert' defaultStr='Ваш лендинг сформирован' page='all' />
        //                 });
        //                 navigate('/[lang]/intro/[id]', `/intro/${profileData?.username}`, queryLang || lang);
        //             },
        //         },
        //         {
        //             title: <T textName='pageForInvestor' defaultStr='Страница для инвестора' page='all' />,
        //             clickFunc: () => {
        //                 setAlertData({
        //                     type: 'success',
        //                     cont: <T textName='presentationLandingAlert' defaultStr='Ваш лендинг сформирован' page='all' />
        //                 });
        //                 navigate('/[lang]/info/[id]', `/info/${profileData?.username}`, queryLang || lang);
        //             },
        //             pressFunc: () => {
        //                 setAlertData({
        //                     type: 'success',
        //                     cont: <T textName='presentationLandingAlert' defaultStr='Ваш лендинг сформирован' page='all' />
        //                 });
        //                 navigate('/[lang]/info/[id]', `/info/${profileData?.username}`, queryLang || lang);
        //             }
        //         },
        //         {
        //             title: <T textName='pageForDistributor' defaultStr='Страница для дистрибьютора' page='all' />,
        //             clickFunc: () => {
        //                 setAlertData({
        //                     type: 'success',
        //                     cont: <T textName='presentationLandingAlert' defaultStr='Ваш лендинг сформирован' page='all' />
        //                 });
        //                 navigate('/[lang]/opportunity/[id]', `/opportunity/${profileData?.username}`, queryLang || lang);
        //             },
        //             pressFunc: () => {
        //                 setAlertData({
        //                     type: 'success',
        //                     cont: <T textName='presentationLandingAlert' defaultStr='Ваш лендинг сформирован' page='all' />
        //                 });
        //                 navigate('/[lang]/opportunity/[id]', `/opportunity/${profileData?.username}`, queryLang || lang);
        //             },
        //         },
        //     ]
        // }
    ];
    if (data && !!data?.length) {
        const sorted = data.sort((a, b) => a.order - b.order);
        const parent = sorted.find(element => element.parent === '')?._id;

        console.log(sorted, 'as sorted');
        sorted.map((item) => {
            if (item.parent === parent) {
                const id = item._id;
                const submenu = [];
                sorted.map((itemChild) => {
                    if (itemChild.parent === id) {
                        submenu.push(itemChild)
                    }
                });
                if(profileData && !profileData?.referral_rule_accepted && item.link == "/structure"){
                    
                } else if (profileData && !profileData?.referral_rule_accepted && item.link == "/page/tools") {

                } else {
                    menu.push({
                        ...item,
                        title: item.title[lang] || item.title.default,
                        icon: item.icon,
                        name: item._id + '-name',
                        submenu: itemMenuChild(submenu, sorted, lang),
                        link: item.type === 'page' ? '/[lang]/page/[page]' : `/[lang]${item.link}`,
                        asLink: !!item.link ? item.link : item._id + '_link'
                    })
                }
               
            }
        })
    }
    if (outerPart) {
        menu.push({
            activity: true,
            asLink: '/',
            icon: 'menu-logout',
            link: '/',
            name: 'logout',
            roles: [],
            showAll: true,
            target: false,
            type: 'link',
            linkNative: 'Home',
            title: <T textName='logoutTitle' defaultStr='Выйти' page='all' />,
            submenu: []
        })
    }
    return menu;
};

export const bottomMenuOfficeData = [
    // {
    //     name: 'orders',
    //     asLink: '/orders',
    //     icon: 'menu-orders',
    //     link: '/[lang]/orders',
    //     title: <T textName='orderTitle' defaultStr='Заказы' page='/office' />
    // },
    {
        name: 'treegraph',
        asLink: '/structure',
        icon: 'menu-structure',
        link: '/structure',
        title: <T textName='structureTitleNew' defaultStr='Структура' page='all' />
    },
    {
        name: 'office',
        asLink: '/office',
        icon: 'menu-office',
        link: '/office',
        title: <T textName='officeTitle' defaultStr='Кабинет' page='all' />
    },
    {
        name: 'wallets',
        asLink: '/wallets',
        icon: 'menu-wallets',
        link: '/wallets',
        title: <T textName='walletsTitleNew' defaultStr='Счета' page='all' />
    },
    // {
    //     name: 'reports',
    //     asLink: '/reports',
    //     icon: 'menu-report',
    //     link: '/reports',
    //     title: <T textName='reportsTitle' defaultStr='Отчеты' page='/office' />
    // }
]