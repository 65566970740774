import React, {
    useContext, useState, useEffect
} from 'react';
import {
    Div, P, svgSprite
} from '../../../hybrid/wrappers';
import T from '../../../common/components/T';
import { navigate } from '../../../common/utils/router';
import UserContext from '../../../common/utils/getContext';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';
import Image from 'next/image';
import styles from './hero.module.scss';

import desktopImg from '../../../public/src/img/landing/hero/hero-bg1.png';
import desktopImgMobile from '../../../public/src/img/landing/hero/hero-bg-mobile1.png';
import percentageBg from '../../../public/src/img/landing/hero/bg-percentage.png';

const HeroSection = () => {
    const { lang } = useContext(UserContext)
    const windowDimensions = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (windowDimensions < 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowDimensions]);

    return (
        <Div>
            <Div className={styles.bgWrapper}>
                <Image
                    alt='hero-background'
                    src={isMobile ? desktopImgMobile : desktopImg}
                    layout='fill'
                    objectFit='cover'
                    quality='100'
                    placeholder='blur'
                    blurDataURL=''
                />
                <Div className={styles.mainContainer}>
                    <Div className={styles.descriptionWrapper}>
                        <Div className={styles.title}>
                            <P>
                                <T textName='title-hero-label-new123123upper' defaultStr='Сообщество "Все в одном".' page='/'/>
                            </P>
                            <P>
                                <T textName='title-hero-label-new123123lower' defaultStr='Бесплатное членство.' page='/'/>
                            </P>
                        </Div>
                        <P className={styles.description}>
                            <T textName='title-hero-label-new-lower123123' defaultStr='Став членом нашего глобального сообщества, вы получите доступ к недвижимости, финансовым решениям, финансовым инструментам, академии финансовой грамотности - и многое другое...' page='/'/>
                        </P>
                        <Div
                            className={styles.regButtonWrapper}
                            onClick={() => navigate('/[lang]/authentication/[type]', '/authentication/login', lang)}
                        >
                            <T textName='login-hero-label-new-new' defaultStr='Вход' page='/'/>
                            <P className={styles.registerIconSvgWrapper}>
                                {svgSprite('register-button-arrow', {
                                    width: isMobile ? '16px' : '21px',
                                    height: isMobile ? '16px' : '21px'
                                })}
                            </P>
                        </Div>
                    </Div>
                </Div>
                <Div className={styles.heroLowerWrapperPart}/>
            </Div>
            <Div className={styles.heroLowerWrapper}>
                <Div className={styles.percentageWrapper}>
                    <P className={[styles.percentageText, styles.percentageTextUpper].join(' ')}>
                        <T textName='description-hero-upper' defaultStr='Видение стало' page='/'/>
                    </P>
                    <P className={[styles.percentageText, styles.percentageTextLower].join(' ')}>
                        <T textName='description-hero-label-lower' defaultStr='реальностью...!' page='/'/>
                    </P>
                    <Image
                        alt='percentage-background'
                        src={percentageBg}
                        layout='fill'
                        objectFit='cover'
                        quality='100'
                        placeholder='blur'
                        blurDataURL=''
                    />
                </Div>
                <Div className={styles.bonusesWrapper}>
                    <Div className={[styles.mainContainer, styles.bonusesWrapperContainer].join(' ')}>
                        <Div className={styles.bonusesHeaderWrapper}>
                            <Div className={styles.dollarSymbolWrapper}>
                                {svgSprite('dollar-symbol', {
                                    width: isMobile ? '32px' : '64px',
                                    height: isMobile ? '32px' : '64px'
                                })}
                            </Div>
                            <Div className={styles.bonusesText}>
                                <span>
                                    <T textName='description-lower-hero-label-newupper' defaultStr='На пути' page='/'/>
                                </span>
                                <span>
                                    <T textName='description-lower-hero-label-newlower' defaultStr=' к созданию' page='/'/>
                                </span>
                            </Div>
                        </Div>
                        <Div
                            className={styles.buttonArrowWrapper}
                            onClick={() => navigate('/[lang]/authentication/[type]', '/authentication/login', lang)}
                        >
                            {svgSprite('button-arrow', {
                                width: isMobile ? '40px' : '60px',
                                height: isMobile ? '40px' : '60px',
                                fill: 'inherit',
                                stroke: 'inherit'
                            })}
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Div>
    )
}
export default HeroSection