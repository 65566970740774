import React, {
    useState, useEffect, useContext
} from 'react';
import {
    Div, P, svgSprite
} from '../../../hybrid/wrappers';
import T from '../../../common/components/T';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';
import { navigate } from '../../../common/utils/router';
import UserContext from '../../../common/utils/getContext';
import Image from 'next/image';
import styles from './wallet.module.scss';

import desktopImg from '../../../public/src/img/landing/wallet/wallet-desktop.png';
import mobileImg from '../../../public/src/img/landing/wallet/wallet-mobile1.png';
import coloredDesktopBg from '../../../public/src/img/landing/wallet/colored-bg-desktop.png';
import coloredMobileBg from '../../../public/src/img/landing/wallet/colored-bg-mobile.png';

const WalletsSection = () => {
    const { lang } = useContext(UserContext)
    const windowDimensions = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (windowDimensions < 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowDimensions]);

    return (
        <Div className={styles.mainWrapper}>
            <Div className={styles.linesBg}/>
            <Div className={styles.bgWrapper}>
                <Image
                    alt='wallet-bg-mobile'
                    src={isMobile ? mobileImg : desktopImg}
                    layout='fill'
                    objectFit='cover'
                    quality='100'
                    placeholder='blur'
                    blurDataURL=''
                />
            </Div>
            <Div className={styles.coloredBgWrapper}>
                <Image
                    alt='colored-bg-mobile'
                    src={isMobile ? coloredMobileBg : coloredDesktopBg}
                    layout='fill'
                    objectFit='cover'
                    quality='100'
                    placeholder='blur'
                    blurDataURL=''
                />
                <Div className={styles.coloredContent}>
                    <Div className={styles.svgWrapper}>
                        {svgSprite('new-connection', {
                            width: isMobile ? '64px' : '96px',
                            height: isMobile ? '64px' : '96px'
                        })}
                    </Div>
                    <P className={styles.title}>
                        <T textName='money-works-label-new12' defaultStr='Получите доступ к ряду преимуществ сообщества' page='/'/>
                    </P>
                    <P className={styles.descriptionFirst}>
                        <T textName='money-works-description-first-new212' defaultStr='Вам доступны: бесценные разработки в финансовой сфере, многочисленные возможности для создания новых источников дохода.' page='/'/>
                    </P>
                    {/* <P className={styles.descriptionSecond}>
                        <T textName='money-works-description-second' defaultStr='до 96% годовых' page='all'/>
                    </P> */}
                    <Div
                        className={styles.buttonWrapper}
                        onClick={() => navigate('/[lang]/authentication/[type]', '/authentication/login', lang)}
                    >
                        <T textName='wallet-button-label-new12' defaultStr='Присоединиться' page='/'/>
                        <P className={styles.svgWalletWrapper}>
                            {svgSprite('register-button-arrow', {
                                width: isMobile ? '16px' : '24px',
                                height: isMobile ? '16px' : '24px'
                            })}
                        </P>
                    </Div>
                </Div>
            </Div>
        </Div>
    )
}
export default WalletsSection